import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'
import { IReport, ICustomer } from './core/Report.Model'
import moment from 'moment'
import Pagination from './Pagination'
import { fetchCustomers, fetchExcelReport, fetchModalData, fetchRequests } from './apis/requestsApi'
import Swal, { SweetAlertOptions } from 'sweetalert2'

interface CustomerRequest {
  data: {
    phoneNumber?: string
    country?: string
    countryCode?: string
    sender?: string
    message?: string
    providerResponse?: string
  }
}

const ReportList: React.FC = () => {
  const intl = useIntl()

  // -----------------------------
  // Table/Pagination/Sorting States
  // -----------------------------
  const [data, setData] = useState<IReport[]>([])
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [orderType, setOrderType] = useState<string>('id')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc')

  // -----------------------------
  // Modal
  // -----------------------------
  const [modalData, setModalData] = useState<any>(null)
  const [customerRequestObj, setCustomerRequestObj] = useState<CustomerRequest>({ data: {} })

  // -----------------------------
  // Two-tier filters approach
  // -----------------------------

  // (A) The “pending” filter states: used by the form inputs
  const [tempSearchValue, setTempSearchValue] = useState('')
  const [tempSearchCustomer, setTempSearchCustomer] = useState('')
  const [tempCreatedAtDateInit, setTempCreatedAtDateInit] = useState('')
  const [tempCreatedAtDateEnd, setTempCreatedAtDateEnd] = useState('')
  const [tempSelectedProduct, setTempSelectedProduct] = useState('')
  const [tempFilterProvider, setTempFilterProvider] = useState('')
  const [tempFilterStatus, setTempFilterStatus] = useState('')

  // (B) The “applied” filter states: actually used in the fetch call
  const [appliedSearchValue, setAppliedSearchValue] = useState('')
  const [appliedSearchCustomer, setAppliedSearchCustomer] = useState('')
  const [appliedCreatedAtDateInit, setAppliedCreatedAtDateInit] = useState('')
  const [appliedCreatedAtDateEnd, setAppliedCreatedAtDateEnd] = useState('')
  const [appliedSelectedProduct, setAppliedSelectedProduct] = useState('')
  const [appliedFilterProvider, setAppliedFilterProvider] = useState('')
  const [appliedFilterStatus, setAppliedFilterStatus] = useState('')

  // We still track the timezone from backend
  const [timezone, setTimezone] = useState('')

  // Trigger for fetch effect
  // We increment this when user clicks “Buscar” or changes page, limit, or sorting
  const [fetchTrigger, setFetchTrigger] = useState(0)

  // -----------------------------
  // Load customers once
  // -----------------------------
  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const config = await getTokenConfig()
        const { customers } = await fetchCustomers(config)
        setCustomers(customers)
      } catch (err: any) {
        setError(err)
      }
    }
    fetchCustomerList()
  }, [])

  // -----------------------------
  // Main fetch effect
  // -----------------------------
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const config = await getTokenConfig()

        // Use the "applied" filters, not the "temp" ones
        const { requests, totalPages, timezone } = await fetchRequests(
          config,
          limit,
          currentPage,
          appliedSearchValue,
          appliedCreatedAtDateInit,
          appliedCreatedAtDateEnd,
          orderType,
          orderDirection,
          appliedSearchCustomer,
          appliedSelectedProduct,
          appliedFilterProvider,
          appliedFilterStatus
        )

        setData(requests)
        setTotalPages(totalPages)
        setTimezone(timezone)
        setIsLoading(false)
      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    // This effect re-runs whenever fetchTrigger changes (user clicked "Buscar" or "Borrar")
    // or user changes page, limit, or sorting
    fetchData()
  }, [
    fetchTrigger,
    currentPage,
    limit,
    orderType,
    orderDirection
  ])

  // ---------------------------------------------
  // Handlers
  // ---------------------------------------------

  // Pressing "Buscar" => copy all "temp" filters to "applied" filters,
  // reset to page 1, increment fetchTrigger so we fetch with new filters
  const handleSearch = () => {
    // Validar fechas: si ambas están definidas, la fecha de inicio no debe ser mayor que la fecha fin
    if (tempCreatedAtDateInit && tempCreatedAtDateEnd) {
      if (moment(tempCreatedAtDateInit).isAfter(moment(tempCreatedAtDateEnd))) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'La fecha de inicio no puede ser mayor que la fecha fin.',
        })
        return
      }
    }

    setAppliedSearchValue(tempSearchValue)
    setAppliedSearchCustomer(tempSearchCustomer)
    setAppliedCreatedAtDateInit(tempCreatedAtDateInit)
    setAppliedCreatedAtDateEnd(tempCreatedAtDateEnd)
    setAppliedSelectedProduct(tempSelectedProduct)
    setAppliedFilterProvider(tempFilterProvider)
    setAppliedFilterStatus(tempFilterStatus)

    setCurrentPage(1)
    setFetchTrigger(prev => prev + 1)
  }

  // Pressing "Borrar" => reset both pending & applied filters, page => 1, re-fetch
  const handleReset = () => {
    // Clear pending
    setTempSearchValue('')
    setTempSearchCustomer('')
    setTempCreatedAtDateInit('')
    setTempCreatedAtDateEnd('')
    setTempSelectedProduct('')
    setTempFilterProvider('')
    setTempFilterStatus('')

    // Clear applied
    setAppliedSearchValue('')
    setAppliedSearchCustomer('')
    setAppliedCreatedAtDateInit('')
    setAppliedCreatedAtDateEnd('')
    setAppliedSelectedProduct('')
    setAppliedFilterProvider('')
    setAppliedFilterStatus('')

    // Reset sort to default if desired
    setOrderType('id')
    setOrderDirection('asc')

    // Page => 1
    setCurrentPage(1)

    // Force new fetch with no filters
    setFetchTrigger(prev => prev + 1)
  }

  // Sorting => set new order & re-fetch
  const handleOrderChange = (newOrderType: string) => {
    // If the user clicks the same column, flip asc/desc
    if (orderType === newOrderType) {
      setOrderDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setOrderType(newOrderType)
      setOrderDirection('asc')
    }
    // reset to page 1 so we see the first page in the new order
    setCurrentPage(1)
  }

  // Changing page => re-fetch with same "applied" filters
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  // Changing page-size => reset page to 1 => re-fetch with same "applied" filters
  const handleChangeLimit = (newLimit: number) => {
    setLimit(newLimit)
    setCurrentPage(1)
  }

  /** Download CSV report (with the currently applied filters). */
  const handleDownload = async () => {
    try {
      Swal.fire({
        html: `
          <div class="spinner-container" style="overflow: hidden;">
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false
      } as SweetAlertOptions)

      const config = await getTokenConfig()
      const url = await fetchExcelReport(
        config,
        appliedSearchValue,
        appliedCreatedAtDateInit,
        appliedCreatedAtDateEnd,
        orderType,
        orderDirection,
        appliedSearchCustomer,
        appliedSelectedProduct,
        appliedFilterProvider,
        appliedFilterStatus
      )

      Swal.close()

      if (url) {
        const link = document.createElement('a')
        link.href = url
        link.download = 'customer_validateidentity_report.csv'
        link.click()
      } else {
        console.error('No se pudo obtener la URL del reporte o el reporte aún no está listo.')
      }
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }

  /** On modal open: fetch extra detail for the given request ID */
  const handleModalClick = async (id: number) => {
    try {
      const config = await getTokenConfig()
      const { data } = await fetchModalData(config, id)
      setModalData(data)

      try {
        const customerRequest = data.customerRequest || '{}'
        setCustomerRequestObj(JSON.parse(customerRequest))
      } catch {
        setCustomerRequestObj({ data: {} })
      }
    } catch (error) {
      console.error('Error al obtener los datos del modal:', error)
    }
  }

  // Memoize data to avoid re-renders
  const memoizedData = useMemo(() => data, [data])

  // Convert sender ID => human-readable
  const senderNames: { [key: string]: string } = {
    '1': 'Claro',
    '2': 'Concepto móvil',
    '3': 'SMS masivo',
    '4': 'Vozmia'
  }

  // Loading or error
  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>
  }

  if (error) {
    return <div>Ocurrió un error</div>
  }

  // ---------------------------------------------
  // Render
  // ---------------------------------------------
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3'>
        {/* SEARCH FILTERS (bound to "temp" states) */}
        <div className='row flex-wrap mb-5'>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(e) => setTempSearchValue(e.target.value)}
                className='form-control form-control-solid form-control-lg'
                value={tempSearchValue}
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>
                {intl.formatMessage({ id: 'REPORT.PARTNER.C' })}
              </label>
              <select
                value={tempSearchCustomer}
                onChange={(e) => setTempSearchCustomer(e.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un socio comercial</option>
                {customers.map((customer) => (
                  <option key={customer.name} value={customer.name}>
                    {customer.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Proveedor</label>
              <select
                value={tempFilterProvider}
                onChange={(e) => setTempFilterProvider(e.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un proveedor</option>
                <option value='Vozmia'>Vozmia</option>
                <option value='SMS masivo'>SMS masivo</option>
                <option value='Concepto móvil'>Concepto móvil</option>
                <option value='Claro'>Claro</option>
              </select>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Estatus</label>
              <select
                value={tempFilterStatus}
                onChange={(e) => setTempFilterStatus(e.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un estatus</option>
                <option value='200'>Aceptado</option>
                <option value='400'>Rechazado</option>
              </select>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha de inicio</label>
              <input
                type='date'
                value={tempCreatedAtDateInit}
                onChange={(e) => setTempCreatedAtDateInit(e.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha fin</label>
              <input
                type='date'
                value={tempCreatedAtDateEnd}
                onChange={(e) => setTempCreatedAtDateEnd(e.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>
        </div>

        {/* ACTION BUTTONS */}
        <div className='row flex-wrap mb-5'>
          <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end'>
            <div className='form-group d-flex justify-content-end'>
              <div className='split-button'>
                {/* "Buscar" => Copy all temp => applied + fetch */}
                <button onClick={handleSearch} className='btn btn-primary rounded-pill'>
                  Buscar
                </button>
                {/* "Borrar" => reset all filters + fetch */}
                <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                  Borrar
                </button>
                {/* Download CSV with the *applied* filters */}
                <button onClick={handleDownload} className='btn btn-secondary rounded-pill'>
                  Descargar reporte
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* TABLE */}
        <div className='table-responsive mb-20'>
          {data.length === 0 ? (
            <p className='mb-0'>
              <strong>No hay resultados disponibles</strong>
            </p>
          ) : (
            <table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th
                    onClick={() => handleOrderChange('id')}
                    className='min-w-50px'
                    style={{ cursor: 'pointer' }}
                  >
                    ID
                  </th>
                  <th>Número de teléfono</th>
                  <th>Proveedor</th>
                  <th>País</th>
                  <th
                    onClick={() => handleOrderChange('customerName')}
                    className='min-w-50px'
                    style={{ cursor: 'pointer' }}
                  >
                    Socio comercial
                  </th>
                  <th
                    onClick={() => handleOrderChange('createdAt')}
                    className='min-w-150px'
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.DATE' })}
                  </th>
                  <th
                    onClick={() => handleOrderChange('statusCode')}
                    className='min-w-150px'
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.STATUS' })}
                  </th>
                  <th
                    onClick={() => handleOrderChange('email')}
                    className='min-w-150px'
                    style={{ cursor: 'pointer' }}
                  >
                    Email
                  </th>
                  <th
                    onClick={() => handleOrderChange('userName')}
                    className='min-w-150px'
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION_CUSTOMER_USERNAME' })}
                  </th>
                  <th className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.ACTIONS' })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {memoizedData.map((item) => (
                  <tr key={item.id}>
                    <td style={{ width: '14ch' }}>{item.id}</td>
                    <td style={{ width: '14ch' }}>{item.entityId}</td>
                    <td style={{ width: '14ch' }}>{item.providerName}</td>
                    <td style={{ width: '14ch' }}>{item.countryName}</td>
                    <td style={{ width: '14ch' }}>{item.customerName}</td>
                    <td style={{ width: '14ch' }}>
                      {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY HH:mm:ss')}
                    </td>
                    <td style={{ width: '14ch' }}>
                      {item.statusCode === '200' ? 'Aceptado' : 'Rechazado'}
                    </td>
                    <td style={{ width: '14ch' }}>{item.email}</td>
                    <td style={{ width: '14ch' }}>{item.userName}</td>
                    <td style={{ width: '14ch' }}>
                      {/* Modal Trigger */}
                      <button
                        type='button'
                        className='dropdown-toggle icon-dropdown'
                        data-bs-toggle='modal'
                        data-bs-target={`#requestModal-${item.id}`}
                        onClick={() => handleModalClick(item.id)}
                      >
                        <i className='bi bi-eye-fill'></i>
                      </button>

                      {/* MODAL */}
                      <div
                        className='modal fade'
                        id={`requestModal-${item.id}`}
                        tabIndex={-1}
                        aria-labelledby='exampleModalLabel'
                        aria-hidden='true'
                      >
                        <div className='modal-dialog modal-dialog-centered modal-lg'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                                Detalle
                              </h1>
                              <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              ></button>
                            </div>
                            <div className='modal-body'>
                              <div className='row col-md-12'>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Fecha de solicitud:</label>
                                  <div>
                                    {modalData?.createdAt
                                      ? moment(modalData.createdAt).format('DD/MM/YYYY HH:mm:ss')
                                      : 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Número de teléfono:</label>
                                  <div>
                                    {customerRequestObj?.data?.phoneNumber ?? 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>País:</label>
                                  <div>
                                    {customerRequestObj?.data?.country ?? 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Country code:</label>
                                  <div>
                                    {customerRequestObj?.data?.countryCode ?? 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Sender Id:</label>
                                  <div>
                                    {customerRequestObj?.data?.sender ?? 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Sender Name:</label>
                                  <div>
                                    {customerRequestObj?.data?.sender
                                      ? senderNames[customerRequestObj.data.sender] ?? 'No disponible'
                                      : 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-4'>
                                  <label className='form-label'>Estatus:</label>
                                  <div>
                                    {modalData?.statusCode === '200' ? 'Aceptado' : 'Rechazado'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-12'>
                                  <label className='form-label'>Mensaje:</label>
                                  <div>
                                    {customerRequestObj?.data?.message ?? 'No disponible'}
                                  </div>
                                </div>
                                <div className='mb-3 col-md-12'>
                                  <label className='form-label'>Respuesta del Proveedor:</label>
                                  <pre
                                    style={{
                                      fontFamily: 'monospace',
                                      fontSize: '14px',
                                      padding: '10px',
                                      backgroundColor: '#f6f8fa',
                                      borderRadius: '4px',
                                      whiteSpace: 'pre-wrap',
                                      wordWrap: 'break-word'
                                    }}
                                  >
                                    {item.providerResponse
                                      ? (() => {
                                          try {
                                            return JSON.stringify(
                                              JSON.parse(item.providerResponse),
                                              null,
                                              2
                                            )
                                          } catch (error) {
                                            console.error(
                                              'Error al analizar providerResponse:',
                                              error
                                            )
                                            return 'Datos inválidos'
                                          }
                                        })()
                                      : 'No disponible'}
                                  </pre>
                                </div>
                              </div>
                            </div>
                            <div className='modal-footer'>
                              <button
                                type='button'
                                className='btn btn-secondary'
                                data-bs-dismiss='modal'
                              >
                                Cerrar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* END MODAL */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* PAGINATION */}
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => handleChangeLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {memoizedData.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                totalPages={totalPages}
                limit={limit}
                lengthData={memoizedData.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ReportList }
